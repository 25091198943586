<template>
	<div class="index">
		<div class="header flex-center" :style="{'padding-top':(vuex_token && vuex_token?.length>3) ? '50px':'0'}">
			<div style="width:95%;">
				<el-carousel height="150px">
					<el-carousel-item v-for="item in swiperList" :key="item.id" @click="bannerClick">
						<div class="flex-center">
							<img :src="item.picPath" width="100%" alt="" />
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div style="margin-top: 3px" class="menu">
			<div class="menu-left"
				v-if="(vuex_token && vuex_token?.length>3) || (member_token && member_token?.length>3)">
				<div style="padding-bottom: 10px; border: 1px solid #aad5ff">
					<div class="menu-left-top">会员窗口</div>
					<div class="menu-input" style="
              text-indent: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            ">
						您好，{{ vuex_user.simpleName || vuex_user.nickName }}
					</div>
					<div class="menu-input" style="text-indent: 20px">
						账号：{{ vuex_user.phone }}
					</div>
					<div class="menu-input" style="text-indent: 20px">
						会员类型：{{ vuex_token ? "企业" : "船员" }}
					</div>
					<div class="menu-input" style="text-indent: 20px">
						<label style="color: rgb(255, 153, 0); font-size: 0.524vw">请定期刷新简历！</label>
						<label style="margin-left: 16px; color: rgb(255, 0, 0)" @click="refresh">立即刷新</label>
					</div>
					<div style="text-align: center; margin-top: 10px">
						<!-- <div class="member-index" @click="jumpCenter">进入管理端</div> -->
						<el-button type="primary" style="width: 50%; font-size: 0.625vw"
							@click="jumpCenter">进入管理端</el-button>
						<!-- <div class="member-out" @click="tuichu">退出登录</div> -->
						<el-button @click="tuichu" style="width: 40%; font-size: 0.625vw">退出登录</el-button>
					</div>
				</div>
				<div style="margin-top: 10px" class="menu-left-top">
					查询船员简历/查询招聘信息
				</div>
				<div class="info">
					<el-form label-width="80px" :model="formLabel" size="mini">
						<el-form-item label="船员职务">
							<el-select v-model="formLabel.job" placeholder="----请选择----" clearable>
								<el-option v-for="(item, index) in job1" :key="index" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="证书等级">
							<el-select v-model="formLabel.level" placeholder="----请选择----" clearable>
								<el-option v-for="(item, index) in level1" :key="index" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="船舶类型">
							<el-select v-model="formLabel.shiptype" placeholder="----请选择----" clearable>
								<el-option v-for="(item, index) in shiptype1" :key="index" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="航线区域">
							<el-select v-model="formLabel.sailLine" placeholder="----请选择----" clearable>
								<el-option v-for="(item, index) in sailLine2" :key="index" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="所在地区">
							<el-cascader placeholder="----请选择----" v-model="selectedOptions" :options="addrOptions"
								@change="sailChange" clearable></el-cascader>
						</el-form-item>
						<el-form-item label="发布时间">
							<el-date-picker v-model="formLabel.create" type="daterange"
								value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始日期"
								end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
						</el-form-item>
						<el-form-item label="关键词"><el-input v-model="formLabel.keyword"
								clearable></el-input></el-form-item>
						<el-form-item label="" style="margin-left: -68px">
							<el-radio v-model="formLabel.list" label="1">招聘信息搜索</el-radio>
							<el-radio v-model="formLabel.list" label="2">船员简历搜索</el-radio>
						</el-form-item>
						<el-form-item size="medium "><el-button type="primary"
								@click="onSeek">立即查找</el-button></el-form-item>
					</el-form>
				</div>
			</div>

			<div v-else class="menu-left">
				<div style="padding-bottom: 10px; border: 1px solid #aad5ff">
					<div class="menu-left-top">会员窗口</div>
					<div class="menu-input" style="margin-top: 20px">
						<div class="menu-input-1">账号：</div>
						<div class="menu-input-2">
							<input placeholder="请输入账号" type="text" name="user" size="13" maxlength="11" class="input"
								v-model="phone" />
						</div>
					</div>
					<div class="menu-input">
						<div class="menu-input-1">密 码：</div>
						<div class="menu-input-2">
							<input placeholder="请输入密码" type="password" name="user" size="13" maxlength="11"
								class="input" v-model="password" />
						</div>
					</div>
					<div style="text-align: center; margin-top: 10px">
						<el-radio v-model="radio" label="1">船员</el-radio>
						<el-radio v-model="radio" label="2">公司</el-radio>
					</div>
					<div style="text-align: center; margin-top: 10px">
						<el-button type="primary" style="width: 50%; font-size: 12px" @click="btn">登录</el-button>
						<el-button style="width: 40%; font-size: 12px" @click="jump('forgetPwd')">忘记密码？</el-button>
						<div class="flex flex-justify-content" style="cursor: pointer">
							<div style="
			      color: #ff0000;
			      text-align: right;
			      margin-right: 10%;
			      margin: 10px 10px;
			    " @click.stop="jump('register_personal')">
								船员注册
							</div>
							<div style="
			      color: #ff0000;
			      text-align: right;
			      margin-right: 10%;
			      margin: 10px 10px;
			    " @click.stop="jump('register_enterprise')">
								企业注册
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="menu-center">
				<div class="menu-center-top">
					<div class="center-top-title">最新船员招聘</div>
					<div class="center-top-more">
						<div style="cursor: pointer" @click="jump('recruitment_recommend')">
							更多>>
						</div>
						<div style="text-decoration: underline; cursor: pointer" @click="postRecruit">
							发布船员招聘信息
						</div>
					</div>
				</div>
				<!-- @click.stop="recruitJumps($event)" -->
				<div class="menu-center-info">
					<vue-seamless-scroll :data="commonssList" :class-option="defaultOption">
						<div class="menu-center-content">
							<a :href="'/recruitDetail/' + item.id + '.html'" class="menu-center-content-s"
								:title="item.company.name + '-' + item.job" v-for="(item, idx) in commonssList"
								:key="idx">
								<button :data-id="item.id" style="cursor: pointer"></button>
								<div class="ellipsis" v-if="item.company">
									{{ item.company.name || "暂无" }}-{{ item.job }}
								</div>
								<div class="ellipsis" v-else>·{{ item.job }}</div>
							</a>
						</div>
					</vue-seamless-scroll>
				</div>
			</div>
			<div class="menu-right" style="margin-left: 8px; display: flex; flex-direction: column; height: 529px">
				<div class="right-top-top">
					<div @click="jump('the_crew_recruitment')" style="
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            ">
						<img src="@/static/peixun.png" alt="" style="width: 2.5w; height: 2.5vw" />
						船员招聘
					</div>
					<div @click="jump('job_wanted')" style="
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            ">
						<img src="@/static/qiuzhi.png" alt="" style="width: 2.5vw; height: 2.5vw" />
						船员求职
					</div>
				</div>
				<div @click="jump('train')" class="right-top-bottom">
					<img src="@/static/zhaopin.png" alt="" style="width: 2.9vw; height: 2.9vw" />
					培训推荐
				</div>

				<div style="
            margin-top: 10px;
            padding-bottom: 15px;
            height: 286px;
            display: flex;
            flex-direction: column;
          ">
					<div class="menu-right-top">最新企业公告</div>
					<div class="info notice" @click="articleJumps($event)" v-if="articles.length > 0">
						<vue-seamless-scroll :data="articles" :class-option="defaultOption">
							<div class="article-s" v-for="(item, idx) in articles" :key="idx">
								<div :data-id="item.id" class="notice-title ellipsis">
									·{{ item.category.name }}
									{{ item.title }}
								</div>
							</div>

						</vue-seamless-scroll>
					</div>
					<p v-else style="text-align: center; padding: 2rem">暂无公告</p>
				</div>
			</div>
		</div>
		<!-- <div class="qzSelect">
      <el-select v-model="type" placeholder="请选择求职或招聘" clearable @change="jumpRout">
        <el-option label="船员求职" value="1"></el-option>
        <el-option label="船员招聘" value="2"></el-option>
      </el-select>
      <el-cascader placeholder="地区不限" :props="{ checkStrictly: true }" v-model="areaInfo" :options="area"
        @change="areaChange" clearable></el-cascader>
      <el-select v-model="tiemInfo" placeholder="时间不限" clearable>
        <el-option v-for="(item, index) in timeData" :key="index" :label="item.name" :value="item.name"></el-option>
      </el-select>
      <el-select v-model="schoolInfo" placeholder="学校不限" clearable>
        <el-option v-for="(item, index) in school" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-cascader placeholder="培训类型不限" :props="{ checkStrictly: true }" v-model="value" :options="options"
        @change="handleChange" clearable></el-cascader>
    </div> -->
		<div class="indexContent">
			<div class="contentItem" v-if="config.isCom">
				<div style="display: flex; justify-content: space-between; align-items: center">
					<div class="contentTitle">
						<div class="titleTit">培训推荐</div>
					</div>
					<div class="more" @click="jump('train')">更多>></div>
				</div>
				<div class="train-list">
					<a :href="'/trainingDetail/' + item.id + '.html'" class="train-item" v-for="(item, idx) in list"
						:key="idx">
						<div class="item-top">
							<div class="train-item-img">
								<img :src="item.schoolIcon" />
							</div>
							<div style="width: 65%">
								<div class="train-item-title">
									<div class="text-ellipsis">{{ item.name }}</div>
								</div>
								<div class="train-item-school">{{ item.school }}</div>
								<div>开课时间：{{ item.schoolTime }}</div>
								<div class="train-item-tag" v-if="item.tagsInfo.length > 0">
									<div v-for="(itm, idx) in item.tagsInfo" :key="idx">{{ itm }}</div>
								</div>
								<div class="train-item-tag" v-else>
									<div>平台推荐</div>
								</div>
							</div>
						</div>
						<div class="item-bottom">
							<div v-if="item.surplus == '已满员'">
								<div class="wasFull">已满员</div>
								<image src="/static/yimanyuan.png" mode=""></image>
							</div>
							<div v-else class="remaining">剩余名额：{{ item.surplus }}</div>
							<div class="price">
								<span v-if="vuex_token">{{ item.companyPriceDifference }}</span>
								<span :class="{ company_price: vuex_token }">{{
                  item.zeroPriceDifference
                }}</span>
							</div>
						</div>
					</a>
				</div>
			</div>
			<div class="contentItem">
				<div style="display: flex; justify-content: space-between; align-items: center">
					<div class="contentTitle">
						<div class="titleTit">最新船员招聘信息 美好航程 与您开启</div>
					</div>
					<div class="more" @click="jump('recruitment_recommend')">更多>></div>
				</div>
				<div class="tb">
					<el-table :data="commonList" style="width: 100%" :header-cell-style="{
              'background-color': 'rgba(32, 137, 255, .2)',
              color: '#333',
              'font-weight': '400',
            }" @row-click="recruitJump">
						<el-table-column prop="job" label="招聘职务"></el-table-column>
						<el-table-column prop="level" label="证书等级"></el-table-column>
						<el-table-column prop="money" label="工资待遇"></el-table-column>
						<el-table-column prop="tonnage" label="吨位"></el-table-column>
						<el-table-column prop="sailLine" label="航线"></el-table-column>
						<el-table-column prop="shipType" label="招聘船型"></el-table-column>
						<el-table-column prop="company.name" label="招聘企业" min-width="180"></el-table-column>
						<el-table-column prop="createTime" label="发布时间" min-width="120">
							<template slot-scope="scope">
								{{ $dy_tool.formatDate(scope.row.updateTime) }}
							</template>
						</el-table-column>
					</el-table>
					<el-pagination style="text-align: right; margin-top: 10px" @current-change="commonChange"
						@prev-click="commonPrevClick" @next-click="commonNextClick" :page-size="10"
						layout="prev, pager, next, jumper" :total="commonTotal"></el-pagination>
				</div>
			</div>
			<div class="contentItem">
				<div style="display: flex; justify-content: space-between; align-items: center">
					<div class="contentTitle">
						<div class="titleTit">最新船员求职信息 美好航程 与您开启</div>
					</div>
					<div class="more" @click="jump('job_wanted_recommend?tab=2')">更多>></div>
				</div>
				<div class="tb">
					<el-table :data="commonsList" style="width: 100%" @cell-click="qiuzhiClick" :header-cell-style="{
              'background-color': 'rgba(32, 137, 255, .2)',
              color: '#333',
              'font-weight': '400',
            }">
						<el-table-column prop="userName" label="姓名"></el-table-column>
						<el-table-column prop="age" label="年龄">
							<template slot-scope="scope">
								<span>{{ scope.row.age || "暂无" }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="expectJob" label="职务"></el-table-column>
						<el-table-column prop="certificate" label="证书等级"></el-table-column>
						<el-table-column prop="sailingYear" label="经验">
							<template slot-scope="scope">
								<span>{{ scope.row.sailingYear || "暂无" }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="shipType" label="船舶类型">
							<template slot-scope="scope">
								<span>{{ scope.row.shipType || "暂无" }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="salaryRoof" label="薪资待遇">
							<template slot-scope="scope">
								<span>{{ scope.row.salaryRoof || "面议" }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="nativeProvince" label="所在地区">
							<template slot-scope="scope">
								<span>{{ scope.row.nativeProvince || "暂无" }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="updateTime" label="更新日期" min-width="150"></el-table-column>
					</el-table>
					<el-pagination style="text-align: right; margin-top: 10px" @current-change="commonsChange"
						@prev-click="commonsPrevClick" @next-click="commonsNextClick" :current-page="commonssPage.page"
						:page-size="10" layout="prev, pager, next, jumper" :total="commonsTotal"></el-pagination>
				</div>
			</div>
		</div>
		<tab-bottom ref="bottom"></tab-bottom>
	</div>
</template>

<script>
	import BaseMixin from "@/mixins/BaseMixin.vue";
	import tabBottom from "@/pages/bottom.vue";
	import vueSeamlessScroll from "vue-seamless-scroll";
	import {
		provinceAndCityData,
		CodeToText,
		TextToCode
	} from "element-china-area-data";

	export default {
		mixins: [BaseMixin],
		data() {
			return {
				addrOptions: provinceAndCityData,
				selectedOptions: [],
				config: require("@/config/config"),
				radio: "1",
				swiperList: [],
				trainPage: {
					page: 0,
					size: 9,
					schoolInfo: "",
					tiemInfo: "",
					province: "",
					city: "",
					categoryId: "",
				},
				list: [],
				recruits: [],
				articles: [],
				phone: "",
				password: "",
				btnStatus: false,
				commonPage: {
					isTop: false,
					page: 0,
					size: 10,
					expectJob: "",
					certificate: "",
					shipType: "",
					companyProvince: "",
				},
				commonssPage: {
					isTop: false,
					page: 0,
					size: 32,
					expectJob: "",
					certificate: "",
					shipType: "",
					companyProvince: "",
				},
				commonList: [],
				commonssList: [],
				commonTotal: 0,
				commonsPage: {
					isTop: false,
					page: 0,
					size: 10,
					job: "",
					level: "",
					shipType: "",
					dwellProvince: "",
				},
				formLabel: {
					job: "",
					level: "",
					shiptype: "",
					sailLine: "",
					dwellProvince: "",
					dwellCity: "",
					city: "",
					create: [],
					keyword: "",
					list: "1",
				},
				job1: [],
				level1: [],
				shiptype1: [],
				create1: [],
				sailLine2: [],
				commonsList: [],
				commonsTotal: 0,
				type: "",
				job: "",
				certificate: "",
				ship: "",
				addr: "",
				jobs: [],
				certificates: [],
				ships: [],
				addrs: [],
				addrOptions: provinceAndCityData,
				selectedOptions: [],
				dwellProvince: "",
				areaInfo: "",
				area: [],
				timeData: [],
				tiemInfo: "",
				schoolInfo: "",
				school: [],
				value: [],
				options: [],
			};
		},
		components: {
			tabBottom,
			vueSeamlessScroll,
		},
		computed: {
			defaultOption() {
				return {
					step: 0.7, // 数值越大速度滚动越快
					limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		watch: {
			schoolInfo: {
				handler(newName, oldName) {
					if (newName !== oldName) {
						this.trainPage.schoolId = newName;
						this.trainList();
					}
				},
			},
			tiemInfo: {
				handler(newName, oldName) {
					if (newName !== oldName) {
						this.trainPage.startDate = newName;
						this.trainList();
					}
				},
			},
		},
		methods: {
			qiuzhiClick(e) {
				this.jump("resumeDetail/" + e.id);
				console.log("求职点击", e);
			},
			initData() {
				this.$u.api.category().then((res) => {
					let info = res.data.content;
					if (info.length > 0) {
						info.forEach((item) => {
							item.value = item.id;
							const infos = item.children || [];
							infos.forEach((subItem) => {
								subItem.value = subItem.id;
								const infoz = subItem.children || [];
								infoz.forEach((grandItem) => {
									grandItem.value = grandItem.id;
								});
							});
						});
					}
					info.forEach((e) => {
						this.options.push(e);
					});
				});
				this.$u.api.schoolList().then((res) => {
					res.data.content.forEach((e) => {
						this.school.push({
							id: e.id,
							name: e.name
						});
					});
				});
				this.$u.api.areaList().then((res) => {
					res.data.forEach((e) => {
						let data = [];
						if (e.citys) {
							e.citys.forEach((e1) => {
								data.push({
									value: e1,
									label: e1
								});
							});
						}
						this.area.push({
							value: e.province,
							label: e.province,
							children: data
						});
					});
				});
				this.$u.api.startTimeList().then((res) => {
					res.data.forEach((e) => {
						this.timeData.push({
							name: e
						});
					});
				});
				// this.addrOptions.forEach(e => {
				//      delete e.children
				//    })
				this.$u.api.indexInfo().then((res) => {
					this.swiperList = res.data.bannersPc || [];
					res.data.recruits &&
						res.data.recruits.forEach((e) => {
							if (e.salaryRoof == 0) {
								e.money = "面议";
							} else {
								e.money = e.salaryRoof;
							}
							// e.money = e.salaryFloor + '~' + e.salaryRoof
						});
					this.recruits = res.data.recruits || [];
				});
				this.$u.api.articleList({
					type: 1
				}).then((res) => {
					this.articles = res.data.content;
				});
				this.$u.api.recruitList(this.commonssPage).then((res) => {
					this.commonssList = res.data.content || [];
					// this.commonTotal = res.data.totalElements
				});

				this.$u.api
					.dict({
						keyStr: "job,sail_line,ship_type,certificate_level"
					})
					.then((res) => {
						this.job1 = res.data.job;
						this.sailLine2 = res.data.sail_line;
						this.shiptype1 = res.data.ship_type;
						this.level1 = res.data.certificate_level;
					});

				this.trainList();

				this.recruitList();
				this.resumeList();
			},
			postRecruit() {
				if (!this.vuex_token && !this.member_token) {
					this.jump("login");
				} else if (this.member_token) {
					this.uni.showToast({
						title: "您不是企业",
						icon: "none",
					});
				} else if (this.vuex_token) {
					this.jump("post_recruitment_info");
				}
			},
			trainList() {
				this.$u.api.trainingSearchPCList(this.trainPage).then((res) => {
					// res.data.content = res.data.content.splice(0,9)
					res.data.content.forEach((e, index) => {
						// if(index < 9) {
						e.tagsInfo = [];
						if (e.tags && e.tags.indexOf(",") > -1) {
							e.tagsInfo = e.tags.split(",");
							if (e.tagsInfo.length > 3) {
								e.tagsInfo = e.tagsInfo.slice(0, 3);
							}
						} else if (e.tags) {
							e.tagsInfo.push(e.tags);
						}
						this.list = res.data.content;
					});
				});
			},
			recruitList() {
				this.$u.api.recruitList(this.commonPage).then((res) => {
					res.data.content.forEach((e) => {
						if (e.salaryRoof == 0) {
							e.money = "面议";
						} else {
							e.money = e.salaryRoof;
						}
					});
					this.commonList = res.data.content || [];
					this.commonTotal = res.data.totalElements;
				});
			},
			resumeList() {
				this.$u.api.resumeList(this.commonsPage).then((res) => {
					this.commonsList = res.data.content || [];
					this.commonsTotal = res.data.totalElements;
				});
			},
			bannerClick(index) {
				if (this.swiperList[index].url) {
					window.location.href = this.swiperList[index].url;
				}
			},
			recruitJumps(row) {
				// if(!this.vuex_token&&!this.member_token) {
				//   this.jump('login')
				// }else {
				if (row.target.dataset.id) {
					this.jump("recruitment_details?id=" + row.target.dataset.id);
				}
				// }
			},
			recruitJump(row) {
				if (!this.vuex_token && !this.member_token) {
					this.jump("login");
				} else {
					this.jump("recruitDetail/" + row.id);
					// this.jump('recruitment_details?id=' + row.id);
				}
			},
			articleJumps(row) {
				if (!this.vuex_token && !this.member_token) {
					this.jump("login");
				} else {
					if (row.target.dataset.id) {
						this.jump("notice_detail?id=" + row.target.dataset.id);
					}
				}
			},
			articleJump(row) {
				if (!this.vuex_token && !this.member_token) {
					this.jump("login");
				} else {
					this.jump("notice_detail?id=" + row.id);
				}
			},
			btn() {
				if (this.btnStatus === true) {
					this.$ui.showToast({
						title: "已经登录了"
					})
					return;
				}
				if (!this.phone) {
					this.$ui.showToast({
						title: "请输入您的手机号"
					});
					return;
				}
				if (this.phone.length < 11) {
					this.$ui.showToast({
						title: "手机号错误"
					});
					return;
				}
				if (!this.password) {
					this.$ui.showToast({
						title: "请输入密码"
					});
					return;
				}
				if (this.password.length < 6) {
					this.$ui.showToast({
						title: "密码不能少于6位"
					});
					return;
				}
				if (this.checked === true) {
					this.$u.vuex("loginInfo", {
						phone: this.phone,
						password: this.password
					});
				}
				this.btnStatus = true;
				if (this.radio === "1") {
					this.$u.api
						.memberPhoneLogin({
							phone: this.phone,
							password: this.password
						})
						.then((res) => {
							this.btnStatus = true;
							this.uni.showToast({
								title: "登录成功"
							});
							this.phone = "";
							this.password = "";
							this.member_token = res.data.token;
							this.vuex_user = res.data.userInfo;
							this.loginStatus = true;

							this.$u.vuex('member_token', res.data.token)
							this.$u.vuex('vuex_user', res.data.userInfo)
							this.$u.vuex('tabIndex', 0)
							localStorage.removeItem('vuex_token')

							this.jump('index')
						})
						.catch((err) => {
							this.uni.showToast({
                title: err.message
							});
							this.btnStatus = false;
						});
				} else {
					this.$u.api
						.companyPhoneLogin({
							phone: this.phone,
							password: this.password
						})
						.then((res) => {
							this.uni.showToast({
								title: "登录成功"
							});
							this.phone = "";
							this.password = "";
							this.vuex_token = res.data.token;
							this.vuex_user = res.data.userInfo;
							this.loginStatus = true;

							this.$u.vuex('loginStatus', true);
							this.$u.vuex('vuex_token', res.data.token);
							this.$u.vuex('vuex_user', res.data.userInfo);
							localStorage.removeItem('member_token')
							this.$u.vuex('tabIndex', 0)

							this.jump('index')
						})
						.catch((err) => {
							this.uni.showToast({
                title: err.message
							});
							this.btnStatus = false;
						});
				}
			},
			jumpCenter() {
				this.index = 1000;
				if (this.member_token) {
					this.$u.vuex("checkIndex", "1");
					this.jump("account_manage");
				} else {
					this.$u.vuex("qyCheckIndex", "1");
					this.jump("enterprise_index");
				}
			},
			tuichu() {
				this.$dialog.confirm({
					title: "温馨提示",
					message: "是否退出账号"
				}).then(() => {
					this.fedLogOut();
				});
			},
			refresh() {
				if (this.member_token) {
					this.$u.api.resumeOneKeyRefresh().then((res) => {
						this.$toast("一键刷新简历成功");
					});
				} else {
					this.$u.api.recruitOneKeyRefresh().then((res) => {
						this.$toast("一键刷新招聘成功");
					});
				}
			},
			jumpRout(e) {
				if (e == 1) {
					this.jump("job_wanted");
				} else if (e == 2) {
					this.jump("the_crew_recruitment");
				}
			},
			handleChange(e) {
				const id = e[e.length - 1];
				this.trainPage.categoryId = id ? id : "";
				this.trainList();
			},
			areaChange(e) {
				this.trainPage.province = e[0];
				this.trainPage.city = e[1];
				this.trainList();
			},
			commonsChange(e) {
				this.commonsPage.page = e - 1;
				console.log("船员求职页面", this.commonPage.page + 1);
				localStorage.setItem("commonsPage_Page", parseInt(this.commonsPage.page + 1));
				this.resumeList();
			},
			commonsPrevClick(e) {
				this.commonsPage.page = e + 1;
				console.log("船员求职页面", this.commonPage.page + 1);
				localStorage.setItem("commonsPage_Page", parseInt(this.commonsPage.page + 1));
				this.resumeList();
			},
			commonsNextClick(e) {
				this.commonsPage.page = e - 1;
				console.log("船员求职页面", this.commonPage.page + 1);
				localStorage.setItem("commonsPage_Page", parseInt(this.commonsPage.page + 1));
				this.resumeList();
			},
			commonChange(e) {
				this.commonPage.page = e - 1;
				this.recruitList();
			},
			commonPrevClick(e) {
				this.commonPage.page = e + 1;
				this.recruitList();
			},
			commonNextClick(e) {
				this.commonPage.page = e - 1;
				this.recruitList();
			},

			sailChange() {
				this.formLabel.dwellProvince = CodeToText[this.selectedOptions[0]];
				this.formLabel.dwellCity = CodeToText[this.selectedOptions[1]];
				// console.log("loc",loc,CodeToText[this.selectedOptions[0]],CodeToText[this.selectedOptions[1]])
				// this.formLabel.sailLine = loc.split("-")[1];
				// this.formLabel.city = loc.split("-")[2];
			},
			onSeek() {
				this.$u.vuex("tabIndex", this.formLabel.list);
				this.$u.vuex("formLabelData", this.formLabel);
				if (this.formLabel.list == 1) {
					this.jump("the_crew_recruitment");
				} else if (this.formLabel.list == 2) {
					this.jump("job_wanted");
				}
			},
		},
	};
</script>
<style>
	page {
		background: none;
		/* background: #f3f4f5; */

		/* background: linear-gradient(#2089FF, #fff); */
	}

	.el-table>>>.warning-row {
		background: #fcfcfc;
	}

	.el-table>>>.success-row {
		background: rgba(32, 137, 255, 0.05);
	}
</style>
<style lang="less" scoped>
	.index {
		margin: 0 15.25vw 120px 15.25vw;
		background: #fff;
		box-shadow: 0px 8px 13px 0px rgba(6, 27, 51, 0.13);

		.indexImg {
			image {
				width: 100vw;
			}
		}

		.qzSelect {
			margin: 40px 1.5vw 0;
			display: flex;
			justify-content: space-between;
		}

		.indexContent {
			padding: 0 1.5vw;
			// margin: 0 18.75vw 120px 18.75vw;
			// padding: 1vw;

			.contentItem {
				padding-top: 3vw;

				&:first-child {
					padding-top: 40px;
				}

				.tb {
					border: 1px solid #aad5ff;
				}

				.el-table {
					padding: 30px 1.5vw 1.5vw;
				}
			}

			.contentTitle {
				width: 50%;
				color: #fff;
				height: 3.2vw;
				line-height: 3.2vw;
				text-indent: 1em;
				font-size: 1.46vw;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: linear-gradient(-90deg, #ffffff, #acdbff);

				.titleTit {
					width: 27vw;
					background: linear-gradient(180deg, #188be1, #acdbff);
				}
			}

			.more {
				font-size: 14px;
				cursor: pointer;
				color: #999;

				&:hover {
					color: #2089ff;
				}
			}

			.train-list {
				border: 1px solid #bfdfff;
				padding: 0 1.5vw 1.5vw;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				color: #333333;

				.train-item {
					cursor: pointer;
					width: 29%;
					padding: 1.5625vw 1vw;
					background: #fff;
					display: inline-block;
					margin-top: 2.083vw;
					font-size: 0.729vw;
					box-shadow: 0px 3px 18px 0px rgba(1, 22, 46, 0.08);

					.item-top {
						border-bottom: 1px solid #f0f0f0;
						display: flex;
						flex-wrap: wrap;
						// justify-content: space-between;
						align-items: center;
						font-size: 0.833vw;

						img {
							width: 5.208vw;
							height: 5.208vw;
							margin-right: 15px;
							object-fit: cover;
						}

						.train-item-title {
							font-size: 1.1458vw;
							margin-bottom: 1.04167vw;
						}

						.train-item-school {
							margin-bottom: 0.5208vw;
						}

						.train-item-tag {
							margin: 1.5625vw 0;
							display: flex;
							font-size: 0.625vw;

							div {
								padding: 0.3125vw 0.5vw;
								margin-right: 0.5208vw;
								color: #2089ff;
								background-color: rgba(32, 137, 255, 0.08);

								&:last-child {
									margin-right: 0;
								}
							}
						}
					}

					.item-bottom {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 1.5625vw;
						position: relative;

						.wasFull {
							position: absolute;
							z-index: 9;
							font-size: 16px;
							color: #fefefe;
							bottom: 5px;
							left: -3px;
						}

						image {
							position: absolute;
							bottom: 0;
							left: -22px;
							width: 4.16vw;
							height: 2.083vw;
						}

						.remaining {
							font-size: 0.833vw;
						}

						.price {
							color: #f95022;
							font-size: 1.25vw;
							font-weight: 500;
						}

						.company_price {
							margin-left: 8px;
							color: #ccc;
							font-size: 1vw;
							text-decoration: line-through;
						}
					}
				}

				&:after {
					content: "";
					width: 31%;
					height: 0px;
					visibility: hidden;
				}
			}
		}
	}

	.announcement-box {
		margin-top: 10px;
		height: 261px;
		padding-bottom: 15px;
		display: flex;
		flex-direction: column;
	}

	@media screen and (max-width: 1280px) {
		.announcement-box {
			height: 273px;
		}
	}

	.menu {
		display: flex;
		// flex-wrap: wrap;
		font-size: 13px;
		height: 680px;

		.menu-left {
			width: 18%;
			margin-right: 8px;
			min-width: 280px;

			// border:1px solid #AAD5FF;
			// border-top:0;
			.menu-left-top {
				// background-image: url(/static/huiyuan.gif);
				color: #fff;
				font-size: 1.15vw;
				background: linear-gradient(0deg, #acdbff, #188be1);
				text-align: center;
				height: 3.2vw;
				line-height: 3.2vw;
				margin-bottom: 10px;
				// font-weight: bold;
			}

			.info {
				flex: 1;
				border: 1px solid #aad5ff;
				line-height: 20px;
				overflow: hidden;
				height: 100%;

				.el-form {
					.el-form-item {
						margin-bottom: 9px;

						.el-select {
							padding-right: 3px;
						}

						.el-date-editor.el-input,
						.el-date-editor.el-input__inner {
							width: 193px;
							padding: 3px 4px;
						}

						.el-input {
							padding-right: 3px;
						}
					}
				}
			}

			.menu-input {
				font-size: 0.625vw;
				height: 28px;
				line-height: 28px;
				display: flex;

				// margin-top:10px;
				.menu-input-1 {
					width: 35%;
					text-align: center;
				}

				.menu-input-2 {
					width: 60%;

					.input {
						vertical-align: middle;
						height: 17px;
						border: 1px solid #bfdfff;
						background: #ffffff;
						font-family: Arial;
						color: #005fec;
						font-weight: bold;
					}
				}
			}
		}

		.menu-center {
			flex: 1;

			// border-top:0;
			.menu-center-top {
				font-size: 1.2vw;

				width: 100%;
				display: flex;
				background: linear-gradient(0deg, #ffffff, #acdbff);
				// background-image: url(/static/ding.gif);
				text-align: center;
				height: 3.2vw;
				line-height: 3.2vw;

				// font-weight: bold;
				// color: #00538A;
				.center-top-title {
					color: #fff;
					width: 40%;
					background: linear-gradient(0deg, #acdbff, #188be1);
				}

				.center-top-more {
					color: #1f92e6;
					width: 60%;
					display: flex;
					justify-content: space-around;
				}
			}

			.menu-center-info {
				height: calc(100% - 4vw);
				display: flex;
				justify-content: space-between;
				border: 1px solid #aad5ff;
				overflow-y: hidden;
				padding: 1.7vh 1.5vh;
			}

			.menu-center-content {
				display: flex;
				flex-wrap: wrap;
				line-height: 30px;
				font-size: 1vw;

				.menu-center-content-s {
					width: calc(50% - 3vh);
					position: relative;
					border: 1px solid #aad5ff;
					padding: 0 0.5vh;
					margin: 0.5vh;

					&:hover {
						background: #51aaec;
						color: #fff;
					}

					button {
						position: absolute;
						opacity: 0;
						top: 0;
						left: 0;
						z-index: 2000;
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.menu-right {

			// height: 530px;
			// width:30%;
			// border-top:0;
			// border-left:0;
			.right-top-top {
				height: 3.2vw;
				line-height: 3.2vw;
				font-size: 1.1vw;
				display: flex;
				color: #fd7c00;
				background: linear-gradient(180deg, #fdfdfd, #dfdfdf);
				text-align: center;

				div {
					width: 50%;
				}
			}

			.right-top-bottom {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 3.2vw;
				line-height: 3.2vw;
				font-size: 1.2vw;
				color: #fd7c00;
				background: linear-gradient(180deg, #fdfdfd, #dfdfdf);
				text-align: center;
			}

			.menu-right-top {
				font-size: 1.15vw;
				background: linear-gradient(0deg, #acdbff, #188be1);
				height: 3.2vw;
				line-height: 3.2vw;
				color: #fff;
				text-align: center;
			}
		}
	}

	.notice {
		overflow: hidden;

		.notice-title {
			cursor: pointer;
			margin-bottom: 5px;
		}
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header {
		padding: 30px 0 0px;
	}
</style>